@import "../../styles/common.scss";

.filter-content {
    margin-top: 1em;
    margin-left: 2em;
    margin-right: 2em;
    max-width: 20em;
}

.filter-input {
    &__input {
        margin-top: 1em;
        height: 2em;
        border-radius: 0.5em;
        border: 1px solid var(--primary-3);
        padding-left: 1em;
    }

    &__list {
        list-style-type: none;
        margin: 0;
    }

    &__list-container {
        position: absolute;
        z-index: 1000;
        background-color: var(--primary-1);
        width: 100%;
        border: 1px solid var(--primary-2);
        max-width: 20em;
        margin-right: 2em;
    }
    
    &__list-item {
        padding: 0.5em 0.8em;
        text-overflow: ellipsis;
        &:nth-child(odd) {
            background-color: var(--primary-2);
        }
    }
}

.filter-list {
    &__item {
        padding: 0.5em;
        align-items: center;
        display: flex;
        border-radius: 0.6em;
        background-color: var(--primary-5);
        color: var(--white);
        margin: 0.2em;
        font-size: 0.8em;
        line-height: 0.8em
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
    }

    &__remove {
        background-color: var(--primary-2);
        display: flex;
        padding: 0.2em;
        width: 1em;
        height: 1em;
        align-items: center;
        justify-content: center;
        margin-left: 0.5em;
        border-radius: 50%;
        cursor: pointer;
    }

    &__remove-icon {
        color: var(--black);
    }
}

.filter-list {
    list-style-type: none;
    padding: 0;
    width: 100%;
    max-width: 20em;

    &.collapsed{
        & li {
            display: none;
        }
        & .filter-list__title__icon {
            transform: rotate(0deg);
        }
    }
}

.filtered-recipes {
    &__list {
        list-style-type: none;
        padding: 0;
        width: 100%;
        max-width: 20em;
    }

    &__list-item {
        cursor: pointer;
        position: relative;
        left: 0;
        background-color: var(--primary-3);
        color: var(--black);
        margin-top: .5em;
        margin-bottom: .5em;
        border-radius: 0.2em;
        &:hover {
            color: var(--primary-4);
            background-color: var(--black);
            left: .1em;
        }
    
        &:active {
            background-color: var(--primary-5)
        }
    }

    &__link {
        display: block;
        height: 100%;
        text-decoration: none;
        padding: 0.7em 0.5em;

        &:visited, &:link{
            color: inherit;
        }
    }
}
