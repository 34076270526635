@import "../../styles/common.scss";
@import '../../styles/color-theme.scss';

@keyframes dropMenuExpand {
    from {
        max-height: 0;
    } to {
        max-height: 15em;
    }
}

.color-picker {
    &__container {
        display: none;
        padding: 10px 10px;
        flex-wrap: wrap;

        @include when-screen-smaller-than(650px) {
            display: flex;
        }
    }
    
    &__color {
        border-radius: 50%;
        width: 1em;
        height: 1em;
        border: 0.75em solid;
        margin-right: 0.5em;
        margin-bottom: 0.5em;
        &--light {
            border-color: white;
        }
        &--dark {
            border-color: black;
        }
    }
}

header {
    background-color: var(--primary-3);
    height: 60px;
}

.navigation-bar{
    max-width: 1200px;
    margin: auto;
    padding: 0.25em;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    
    &__link-container {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        @include when-screen-smaller-than(650px) {
            transition: linear 0.25s;
            overflow: hidden;
            width: 0;
            position: absolute;
            box-sizing: border-box;
            height: 100%;
            z-index: 1000;
            flex-direction: column;
            top: 0;
            left: 0;
            background-color: var(--primary-3);
        }
        &.expanded {
            width: 100%;
        }
    }
    
    &__link {
        @include when-screen-smaller-than(650px) {
            margin: 0;
            padding: 20px 10px;
            box-sizing: border-box;
            width: 100%;
        }
        width: fit-content;
        padding: 10px 10px;
        text-decoration: none;
        align-items: center;
        display: flex;
        flex: none;
        color: var(--black);
        border-radius: 4px;
        margin: 0.4em;
        &:visited, &:link {
            color: var(--black);
        }
        &:hover {
            color: var(--black);
            background-color: var(--primary-2);
        }
    }
    
    &__menu-icon {
        display: none;
        height: 2em;
        width: 2em;
        margin-left: 0.3em;
        color: var(--black);
        fill: var(--black);

        @include when-screen-smaller-than(650px) {
            display: block;
        }
    }

    &__close-icon {
        display: none;

        @include when-screen-smaller-than(650px) {
            stroke: var(--black);
            width: 30px;
            margin: 10px;
            height: 30px;
            cursor: pointer;
            display: block;
        }
    }

    &__icon {
        height: 1.5em;
        width: 1.5em;
        padding-right: 1em;
    }
}

.search {
    position: absolute;
    right: 0;
    top: 0;
    background-color: var(--primary-1);
    height: 100%;
    max-width: 500px;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 100;
    overflow: hidden;
    transition: linear 0.5s;
    
    &--expanded {
        width: 100%;
    }

    &--collapsed {
        width: 0;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin: 0.5rem;
        gap: 5%;
    }

    &__close-button {
        padding: 7px;
        background-color: var(--primary-3);
        border: none;
        color: var(--black);
        border-radius: 5PX;
        font-size: 16px;
    }

    &__icon {
        height: 2em;
        width: 2em;
        float: right;
        color: var(--black);
        fill: var(--black);
        stroke: var(--black);
        margin-left: auto;
        margin-right: 1rem;
        align-self: center;
        cursor: pointer;
    }

    &__input {
        float: inherit;
        background: transparent;
        border: none;
        border-bottom: solid 1px var(--primary-4);
        width: 100%;
        font-size: 16px;
        color: var(--black);

        &:focus {
            outline: none;
        }
    }

    &__results-list {
        list-style-type: none;
        padding: 1em;
        margin: 0;
        max-height: 100%;
        overflow: auto;
        &.no-results {
            display: none;
        }
    }

    &__result-item {
        padding: 0.5em 0;
        & a {
            text-decoration: underline;
            background-color: initial;
        }
        a:visited, a:link {
            color: var(--black);
        }
    }
}

.tabs {
    &__container {
        display: flex;
        margin-left: 2em;

    }
    &__tab {
        background-color: var(--primary-3);
        align-items: center;
        border-radius: 0.5em;
        width: 5em;
        margin-right: 1em;
        text-decoration: none; 
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 0.5em;
        display: inline-block;
        &:visited, &:link {
            color: var(--black);
        }
    }
}
