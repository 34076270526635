$login-header-color:            $error-red;
$login-submit-bg-color:         var(--primary-2);
$login-submit-text-color:       var(--primary-4);
$login-input-focus-border:      var(--primary-3);
$login-input-incorrect-border:  $error-red;

.login-header {
    font-size: 1.5em;
    color: $login-header-color;
    margin-top: 0.5em;
    margin-bottom: 1em;
    font-weight: bold;
}
.password-input {
    border-radius: 5px;
    display: block;
    width: 20em;
    height: 2.3em;
    padding: 0.5em 1em;
    border-radius: 0.3em;
    border-style: solid;
    border-width: 1px;
    border-color: var(--primary-1);
    background-color: var(--white);
    box-sizing: border-box;
    &:focus{
        outline: 0;
        border-color: $login-input-focus-border;
        box-shadow: 0 0 2px 2px $login-input-focus-border;
    }
    &.incorrect-password {
        border-color: $login-input-incorrect-border;
        outline: 0;
        box-shadow: 0 0 2px 2px $login-input-incorrect-border;
        
    }
}

.login-incorrect-password-text {
    margin-top: 5px;
    font-size: 0.9em;
    color: $login-input-incorrect-border;
}

.submitbutton.login {
    margin-top: 2em;
    background-color: $login-submit-bg-color;
    color: $login-submit-text-color;
}