@import "../../styles/common.scss";

h4 {
    position: relative;
}
.category-list {
    list-style-type: none;
    padding: 0;
    max-width: 20em;
    margin-left: 2em;
    margin-right: 2em;

    &__header {
        margin-left: 2em;
        margin-top: 1em;
        font-size: 1.5em;
        font-weight: bold;
        color: var(--primary-5);
    }
}

.category-item {
    cursor: pointer;
    position: relative;
    left: 0;
    background-color: var(--primary-3);
    color: var(--black);
    margin-top: .5em;
    margin-bottom: .5em;
    border-radius: 0.2em;

    &__link {
        display: block;
        height: 100%;
        text-decoration: none;
        padding: 0.7em 0.5em;

        &:visited, &:link{
            color: inherit;
        }
    }

    &:hover {
        color: var(--black);
        background-color: var(--primary-4);
        left: .1em;
    }

    &:active {
        background-color: var(--primary-5)
    }

    .text {
        position: relative;
        top: -3px;
    }
}
