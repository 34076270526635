@import "../../styles/common.scss";

.ingredient-block, .instruction-block{
    display: flex;
    &--selected input {
        background-color: var(--primary-2);
    }
    &:hover input {
        background-color: var(--primary-2);
    }
}

.recipeform-ingredient-quantity-group {
    margin-left: 2em;
}

.instruction-block{
    display: flex;
    width: 100%;
}

.recipeform-instruction-group {
    box-sizing: border-box;
    width: 100%;
    margin-left: 2em;
}

.remove{
    cursor: pointer;
    height: 20px;
    width: 20px;
}

.icon-container.icon-remove {
    cursor: pointer;
    margin: 0.4em;
    padding: 0.4em;
    height: 0.8em;
    width: 0.8em;
    border-radius: 50%;
    font-size: 0.8em;
    line-height: 0.8em;
    text-align: center;
    color: var(--black);
    font-weight: bold;
}

.submitbutton{
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right: 0.5em;

    background-color: var(--primary-4);
    border: none;
    border-radius: 6px;
    text-align: center;
    padding: 1em;
    font-weight: bold;
    &.reset{
        background-color: var(--white);
        color: var(--black);
    }
    &.delete{
        background-color: var(--white);
        color: var(--black);
    }
}
.movebutton{
    height: 1em;
    padding: 0.2em;
    cursor: pointer;
    background-color: var(--primary-3);
    border-radius: 0.6em;
    margin: 0.1em;

    &.movedown {
        transform: rotate(180deg)
    }
}
.movebuttons-container{
    width: 1.5em;
    margin-bottom: 0.2em;
    margin-top: -0.9em;
    margin-right: 0.5em;
    position: absolute;
}
.form-group{
    width: 100%;
}
.recipeform-block{
    display: block;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}

label.recipeform-block{
    max-width: 100%;
    //margin-bottom: 5px;
    font-weight: 700;
    font-size: 14px;
}

.recipeform-header {
    font-size: 1.5em;
    color: var(--black);
    margin-top: 0.5em;
    margin-bottom: 1em;
    font-weight: bold;
}

.recipeform__input{
    display: block;
    width: 100%;
    height: 2.3em;
    padding: 0.5em 1em;
    border-style: solid;
    border-width: 1px;
    border-color: var(--primary-1);
    background-color: var(--white);
    color: var(--black);
    box-sizing: border-box;
    &:focus{
        outline: 0;
        border-color: var(--primary-3);
        box-shadow: 0 0 2px 2px var(--primary-3);
    }
    &.invalid{
        border-color: $error-red;
        &:focus{
            box-shadow: 0 0 2px 2px $error-red;
            border-width: 2px;
            border-color: $error-red;        
        }
    }
}

.recipeform__ingredient-buttons {
    margin-left: 2em;
}

.ingredient-row {
    margin-left: 2em;
    display: flex;
}

.ingredient-label {
    width: 10em;
    &__block {
        margin-left: 2em;
        display: flex;
    }
}

.validated-input {
    &__errors {
        color: red;
    }
}

.recipeform-group{
    background-color: var(--primary-1);
    border-radius: 5px;
    padding: 1em;
    margin: 1em 0.2em;
    &__header {
        margin-bottom: 1em;
    }
}
.recipeform-add-button{
    background-color: var(--primary-4);
    border: none;
    border-radius: 6px;
    text-align: center;
    padding: 0.5em;
    font-weight: bold;
    margin: 0.2em;
    &.add-instruction {
        margin-left: 2.2em;
    }
}
.description{
    height: 5em;
    resize: vertical;
    margin-top: 0px;
    margin-bottom: 0px;
}

.recipeform__error {
    font-weight: normal;
    font-size: 0.9em;
    color: $error-red;
}
