.version-info {
    border: 1px solid var(--primary-4);
    border-radius: 0.5em;
    margin-top: 1em;
    background-color: var(--primary-1);
    overflow: hidden;

    &__header {
        border-bottom: 1px solid var(--primary-4);
        padding: 0.5em;
        background-color: var(--primary-2);
        font-weight: bold;
    }

    &__text {
        color: var(--primary-3);
    }

    &__feature-list {
        list-style-type: disc;
        margin: 0.5em 0 0 1em;
    }

    &__feature-list-item {
        padding: 0.5em 0;
    }

    &__body {
        padding: 0.5em;
    } 
  
    &__ok-button {
        background-color: var(--primary-2);
        border: none;
        border-radius: 6px;
        text-align: center;
        padding: 0.5em;
        font-weight: bold;
        margin: 0 0.5em 0.5em 0;
        float: right;
    }
}