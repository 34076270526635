@import '../components/menu/menu';
@import '../components/listViews/categoryList';
@import '../components/listViews/recipeList';
@import '../components/recipeView/recipeView';
@import '../components/recipeEditing/recipeEditing';
@import '../commonComponents/loadSpinner';
@import '../commonComponents/modalDialog';
@import './loginPage';
@import '../components/filtering/recipe-filtering';
@import '../components/landingPage/landing-page';
@import "../components/AdminTools/ManageIngredients";

body {
    background-color: var(--primary-1);
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
}

.main-content {
    margin: auto;
    background-color: var(--white);
    color: var(--black);
    min-height: 98vh;
    padding-bottom: 2vh;
    background: linear-gradient(var(--primary-2),var(--primary-1));
}

.icon {
    color: var(--black);
    fill: var(--black);
    stroke: var(--black);
}

svg {
    overflow: visible;
}
