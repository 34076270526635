@import './version-info';

.landing-page-title {
    &__container {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1em;
    }
    &__logo {
        width: 100px;
    }
    &__title {
        margin-top: 0.5em;
        font-size: 1.4em;
        color: var(--primary-4);
    }
}

.landing-page-favorites,
.landing-page-history {
    &__container {
        display: flex;
        width: 100%;
        flex-direction: column;

        @include when-screen-smaller-than(650px) {
            margin-top: 2rem;
        }
    }

    &__title {
        font-size: 1.2em;
        font-weight: bold;
        margin: 0 0 0.5em 0;
        color: var(--primary-5);
        border-bottom: 1px solid var(--primary-4);
    }

    &__item {
        margin-top: 0.5rem;
    }

    &__link {
        text-decoration: none;
        color: var(--black);
    }

    &__list {
        width: 100%;
        list-style-type: none;
        margin: 0;
        &--no-content {
            color: var(--black);
        }
    }
}

.landing-page__container {
    display: flex;
    gap: 5%;
    margin: auto;
    margin-top: 2rem;
    padding: 0 1rem;

    @include when-screen-smaller-than(650px) {
        flex-direction: column;
        margin-top: 0;
    }
}