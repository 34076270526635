@import "../../styles/common.scss";

ul {
    padding: 0;
}

.recipe-favorite-control {
    float: right;
    margin-right: 12px;
    font-size: 1.25em;
    color: #999999;
    &--is-favorite {
        color: $favourite-yellow;
    }
}

.recipe-details {
    &__content {
        margin: auto;
        padding: 1rem;
        max-width: 1200px;
    }

    &__instructions {
        display: flex;
        margin-top: 1rem;
        gap: 5%;
        @include when-screen-smaller-than(750px) {
            flex-direction: column;
        }
    }

    &__title-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__picture {
        width: 100%;
        height: 15em;
        object-fit: cover;
    }

    &__picture-upload--input {
        display: none;
    }

    &__buttons {
        display: flex;
        align-items: center;
    }

    &__header {
        font-size: 1.5em;
        color: var(--primary-5);
        margin-bottom: 1em;
        font-weight: bold;
        font-family: $header-font;
    }

    &__icon {
        float: right;
        height: 24px;
        width: 24px;
        margin-right: 12px;
    }
}

.unit-changer {
    &__list {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 5em;
        padding: 0.5em;
        background-color: var(--white);
        z-index: 1;
        border-radius: 0.25em;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -5px;
            border: 5px solid transparent;
            border-right-color: var(--white);
        }
    }
    &__list-item {
        padding: 0.5em;
        border-radius: 0.1em;
        background-color: var(--primary-1);
        margin: 0.25em 0;
        cursor: pointer;

        &:hover {
            background-color: var(--primary-2);
        }

        &--selected {
            background-color: var(--primary-3);
        }
    }
}

.portion-control{
    &__container {
        display: flex;
        align-items: center;
    }

    &__portions-label {
        width: 80px;
        color: var(--black);
        font-size: 16px;
        font-weight: normal;
        display: inline-block;
    }

    &__change-portions {
        font-size: 20px;
        background-color: var(--white);
        height: 30px;
        width: 30px;
        border-radius: 10px;
        text-align: center;
        margin: 3px;
        cursor: pointer;
        line-height: 30px;
    }

    &__portion-number{
        background-color: transparent;
        font-weight: bold;
        cursor: default;
    }
}

.ingredient-list{
    list-style-type:none;
    margin: 0;

    @include when-screen-smaller-than(750px) {
        margin-bottom: 2rem;
    }

    &__subtitle {
        color: var(--black);
        font-weight: bold;
        margin-top: 2em;
        margin-bottom: 0.5em;
        font-size: 1.2em;
        font-family: $header-font;
        &:first-child {
            margin-top: 0;
        }
    }

    &__ingredient-quantity {
        display: inline-block;
        width: 90px;
        min-width: 90px;
        height: 20px;
        margin: .5em 0;
        color: var(--primary-5);
        font-weight: bold;
        cursor: pointer;
        &--input {
            border: 1px solid var(--primary-3);
            border-radius: 4px;
            width: 60px;
            min-width: 60px;
            margin-right: 28px;
            padding-left: 0.5em;
        }
    }

    &__ingredient-container {
        display: flex;
        font-family: 'Courier New', Courier, monospace;
    }
    
    &__ingredient-name {
        align-self: center;
        font-weight: bold;
    }
    &__ingredient-input {
        display: flex;
        align-items: center;
        position: relative;
    }
}

.upload-dialog {
    &__progress-bar {
        height: 2em;
        border: solid 1px var(--primary-4);
        background-color: var(--primary-1);
        border-radius: 0.25em;
        &--filler {
            background-color: var(--primary-2);
            height: 100%;
            transition-property: width;
            transition-duration: 0.5s;
        }
    }
}

.instructions-list {
    &__list {
        margin: 0;
    }

    &__instruction {
        color: var(--black);
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: justify;
    }
}
.description{
    background-color: var(--primary-2);
    padding: 1em;
    border-radius: 0.5em;
    margin: 20px 0 10px 0;
}
