@import "../../styles/common.scss";

.ingredient-list {
    &__ingredient, &__manage-ingredient {
        padding: 1em;
        margin: 0.5em;
        background-color: var(--primary-2);
        &--changed {
            background-color: green;
        }
    }
    
}
