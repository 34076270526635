
$edit-input-focus-border:                   var(--primary-3);
$edit-input-focus-shadow:                   var(--primary-3);
$modal-header-background-color: var(--primary-2);
$modal-header-text-color:       var(--primary-3);

$modal-text-background-color:   white;
$modal-text-text-color:         black;

$modal-footer-background-color: var(--primary-2);
$modal-footer-text-color:       var(--primary-3);

$modal-primary-button-bg-color:     var(--primary-2);
$modal-secondary-button-bg-color:   var(--primary-1);

$error-header-background-color: var(--primary-1);
$error-header-text-color:       $error-red;

$error-text-background-color:   white;
$error-text-text-color:         $error-red;

$error-footer-text-color:       var(--black);
$error-footer-background-color: var(--primary-1);

$error-primary-button-bg-color: var(--primary-4);

@mixin dialog-buttons() {
    margin-left: 0.5em;
    border-radius: 5px;
    border-width: 0;
    padding: 0.5em 1em;
}

.modal-dialog{
    width: 80%;
    max-width: 400px;
    margin: auto;
    margin-top: 5em;
    background-color: white;

    &__background {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba($color: var(--black), $alpha: 0.4);
    }

    &__header {
        background-color: $modal-header-background-color;
        color: $modal-header-text-color;
        padding: 1em;
        font-weight: bold;
    }

    &__message {
        background-color: $modal-text-background-color;
        color: $modal-text-text-color;
        padding: 2em 1em;
        min-height: 2em;
    }

    &__input {
        display: block;
        width: 75%;
        height: 2.3em;
        padding: 0.5em 1em;
        border-radius: 0.3em;
        border-style: solid;
        border-width: 1px;
        border-color: var(--primary-1);
        background-color: var(--white);
        box-sizing: border-box;
        margin-top: 1em;
        &:focus{
            outline: 0;
            border-color: $edit-input-focus-border;
            box-shadow: 0 0 2px 2px $edit-input-focus-shadow;
        }
    }

    &__footer {
        background-color: $modal-footer-background-color;
        color: $modal-footer-text-color;
        padding: 1em;

        &__buttons {
            text-align: right;
        }
    }

    &__primary-button {
        @include dialog-buttons();
        background-color: $modal-primary-button-bg-color;
    }

    &__secondary-button {
        @include dialog-buttons();
        background-color: $modal-secondary-button-bg-color;
    }
}

.error-dialog{
    width: 80%;
    max-width: 400px;
    margin: auto;
    margin-top: 5em;
    background-color: white;
    overflow: hidden;
    border-radius: 1em;

    &__background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($color: var(--black), $alpha: 0.4);
    }

    &__header {
        background-color: $error-header-background-color;
        color: $error-header-text-color;
        padding: 1em;
        font-weight: bold;
    }

    &__message {
        background-color: $error-text-background-color;
        color: $error-text-text-color;
        padding: 2em 1em;
        min-height: 4em;
        font-weight: bold;
    }

    &__footer {
        background-color: $error-footer-background-color;
        color: $error-footer-text-color;
        padding: 1em;

        &__buttons {
            text-align: right;
        }
    }

    &__primary-button {
        border-radius: 5px;
        background-color: $error-primary-button-bg-color;
        border-width: 0;
        padding: 0.5em 1em;
    }
}