$header-font: "verdana";

@mixin when-screen-smaller-than($max) { 
    @media (max-width: $max) { 
        @content 
    } 
};

.back-button{
    cursor: pointer;
    height: 50px;
}
.content{
    margin-left: 2em;
    margin-right: 2em;
    max-width: 700px;
}

.content-container {
    max-width: 1200px;
    margin: auto;
}
