$favourite-yellow: #FFB208;
$error-red: #FF5C4B;

:root {
    --selected-hue: 213;
    --primary-1: hsl(var(--selected-hue),80%,95%);
    --primary-2: hsl(var(--selected-hue),80%,90%);
    --primary-3: hsl(var(--selected-hue),80%,80%);
    --primary-4: hsl(var(--selected-hue),80%,40%);
    --primary-5: hsl(var(--selected-hue),80%,15%);
    --white: #fefefe;
    --black: #161616;
}

:root.dark {
    --selected-hue: 213;
    --primary-1: hsl(var(--selected-hue),80%,5%);
    --primary-2: hsl(var(--selected-hue),80%,10%);
    --primary-3: hsl(var(--selected-hue),80%,20%);
    --primary-4: hsl(var(--selected-hue),80%,60%);
    --primary-5: hsl(var(--selected-hue),90%,85%);
    --white: #333333;
    --black: #cecece;
}
