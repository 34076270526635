.loading-spinner {
    height: 1.5em;
    width: 1.5em;
    border-color: var(--primary-1);
    border-radius: 50%;
    border-top: var(--primary-3);
    border-right: var(--primary-3);
    border-width: 0.4em;
    border-style: solid;
    animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;
}
.loading-pot {
    text-align: center;
    margin-top: 10%;
}

@keyframes spin {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}

@-webkit-keyframes spin {
    0% {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(360deg);}
}
